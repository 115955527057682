<template>
  <div>
    <form
      action
      data-vv-scope="box-form"
      @submit.prevent="validateForm('box-form')"
    >
      <v-row style="display: flex; justify-content: center">
        <v-col cols="12"></v-col>
        <v-col id="time" cols="6" md="2" lg="2" class="py-md-3 pb-0 pl-md-10">
          <v-menu
            ref="menu"
            v-model="menu"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <label>{{ $t("events.date") }}</label>
              <v-text-field
                outlined
                hide-details
                dense
                v-validate="'required'"
                data-vv-name="startDate"
                :error-messages="errors.collect('box-form.startDate')"
                readonly
                :value="date ? $d(new Date(date), 'date2digits') : ''"
                v-bind="attrs"
                v-on="on"
                v-bind:class="{ 'error--text': horarioError }"
              >
                <template v-slot:prepend-inner
                  ><v-icon small class="mt-1">$appointments</v-icon></template
                >
              </v-text-field>
            </template>

            <v-date-picker
              first-day-of-week="1"
              v-model="date"
              no-title
              scrollable
              :allowed-dates="allowedDates"
              :min="today"
              color="primary"
              @input="action = true"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" md="2" class="pr-md-6"
          ><label v-text="$t('box')" />
          <v-select
            :items="boxesFiltered"
            v-model="res.id"
            single-line
            item-value="id"
            dense
            outlined
            hide-details
            :disabled="!date"
            data-vv-name="boxes"
            :error-messages="errors.collect('box-form.boxes')"
            data-vv-validate-on="blur"
            v-validate="'required'"
            style="display: inline-block; width: 100%"
            ><template slot="selection" slot-scope="data">
              {{ data.item.name }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.name }}
            </template></v-select
          >
        </v-col>
        <v-col cols="5" md="2">
          <label v-text="$t('events.startTime')" />
          <v-select
            :items="hours"
            v-model="res.start"
            dense
            outlined
            :disabled="!date"
            hide-details
            data-vv-validate-on="blur"
            v-validate="'required'"
            v-bind:class="{ 'error--text': timeError || horarioError }"
            data-vv-name="startTime"
            :error-messages="errors.collect('box-form.startTime')"
          ></v-select>
        </v-col>
        <p
          class="mb-0 mt-11 pa-0"
          style="
            display: inline-block;
            text-align: center;
            color: var(--v-primary-base);
          "
        >
          -
        </p>
        <v-col cols="5" md="2">
          <label v-text="$t('events.endTime')" />
          <v-select
            :items="hours"
            v-model="res.end"
            hide-details
            v-bind:class="{ 'error--text': timeError || horarioError }"
            :disabled="!date"
            dense
            data-vv-validate-on="blur"
            v-validate="'required'"
            :error-messages="errors.collect('box-form.endTime')"
            data-vv-name="endTime"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="horarioError" class="py-0" justify="center">
        <v-col
          cols="12"
          md="6"
          class="login_error white--text pa-1 text-center rounded-xl my-0"
          >{{ $t("time_out_calendar") }}</v-col
        >
      </v-row>
      <div class="pa-3 text-right">
        <v-btn
          outlined
          style="height: 25px"
          v-if="!$store.getters['auth/isTattooer']"
          @click="$emit('back')"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("back") }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          style="height: 25px"
          elevation="0"
          class="ml-2"
        >
          {{ $t("next") }}
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </form>
    <!--
    <v-row
      style="display: flex; justify-content: center"
      v-if="res.date != null"
    >
      <v-simple-table class="pb-5 mt-5" id="table">
        <tr>
          <th>Horas</th>
          <th :key="key.id" v-for="key in taula" style="cursor: pointer">
            {{ key.name }}
          </th>
        </tr>
        <tr>
          <th></th>
          <th :key="key.id" v-for="key in taula">
            <p
              class="mb-0"
              v-if="key.tattooer_id != null"
              :style="`cursor: pointer;background-color:${key.tattooer.color} !important`"
            >
              {{ key.tattooer.user.first_name }}
              {{ key.tattooer.user.last_name }}
            </p>
            <p v-else></p>
          </th>
        </tr>
        <tr :key="h" v-for="h in hours">
          <td>{{ h }}</td>
          <template v-for="box in taula">
            <td
              :key="box.id"
              v-if="res.start === h && res.id === box.id"
              :style="` background-color: blue`"
            ></td>
            <td
              :key="box.id"
              v-else-if="res.end === h && res.id === box.id"
              :style="` background-color: blue`"
            ></td>
            <td
              :key="box.id"
              v-else-if="rowspanSelection(h, box)"
              :style="` background-color: DeepSkyBlue`"
            ></td>
            <td
              :key="box.id"
              v-else-if="rowspan(h, box)"
              :style="` background-color: red`"
            ></td>
            <td
              v-else
              :key="box.id"
              @click="reserva(h, box)"
              style="background-color: green"
            ></td>
          </template>
        </tr>
      </v-simple-table>
    </v-row>
    -->
  </div>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "InformationBox",
  props: ["res"],
  watch: {
    date: {
      handler() {
        this.fetchTable();

        this.res.date = this.date;
        //console.log(this.res.date);
      },
      deep: true,
    },
  },
  mounted() {
    this.res;
    this.today = moment().format("YYYY-MM-DD");
    this.fetchAllVacationsTattooer("none", this.studio.id);
  },
  data() {
    return {
      menuFrom: false,
      menuTo: false,
      today: null,
      date: null,
      menu: false,
      action: false,
      taula: [],

      boxes: [],
      studio_id: null,
      res: {},
    };
  },

  computed: {
    boxesFiltered() {
      /*if (this.havePayments) {
        return this.boxes.filter((x) => true);
      }*/
      return this.boxes;
    },
    havePayments() {
      if (this.studio.user) {
        let s = this.studio.user.setting.find((x) => {
          return x.key == "box_price";
        });
        if (s) return s.value === "1";
      }
      return false;
    },
    timeFrom() {
      if (this.studio.timetable && this.studio.timetable.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.studio.timetable.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
      }
    },
    horarioError() {
      console.log(this.date);
      console.log(this.res);
      if (this.date && this.res.start && this.res.end) {
        console.log("entramos");
        let dh = this.timeTable(this.date)[this.dayOfWeek(this.date)];

        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.res.start && h[1] >= this.res.end) error = false;
        });
        return error;
      }
      return false;
    },
    timeTo() {
      if (this.studio.timetable && this.studio.timetable.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.studio.timetable.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;
      }
    },
    hours() {
      if (this.studio.timetable && this.studio.timetable.length > 0) {
        let mins = [":00", ":15", ":30", ":45"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        //console.log(hours);
        hours.push(last + ":00");
        if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
        return hours;
      }
    },
    ...mapState("inscriptions", ["studioState"]),

    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    timeError() {
      if (this.res.start != undefined && this.res.end != undefined) {
        let s = this.res.start.split(":");
        let e = this.res.end.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] > e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions("boxes", ["getBoxes", "getTable", "getAllBoxes"]),

    ...mapActions("tattooers", ["reservationBox", "editTattooerBox"]),
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      console.log(tattooer);
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    allowedDates(date) {
      let a = this.studio.vacations.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) {
        return false;
      }
      let t = this.datesTattooer.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (t) {
        //console.log(t);
        return false;
      }
      if (this.filter(date)) return this.horario(date).length;
    },
    filter(date) {
      return this.studio.tattooer_studios.find((element) => {
        if (
          this.$store.getters["auth/isTattooer"] &&
          element.tattooer_id !== this.$store.state.auth.user.tattooer.id
        ) {
          return false;
        }
        if (element.to) {
          // comprovar que estigui al interval
          return element.from <= date && date <= element.to;
        } else {
          //comprovar que sigui mes gran que from
          return element.from <= date;
        }
      });
    },
    horario(date) {
      if (date) {
        return this.timeTable(date)[this.dayOfWeek(date)];
      }
      return null;
    },
    timeTable(date) {
      if (this.filter(date).tattooer_timetable)
        return this.filter(date).tattooer_timetable;
      return this.studio.timetable;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      let result = await this.$validator.validateAll(scope);
      let timeVal = true;

      if (this.res.start > this.res.end) {
        timeVal = false;
      }

      if (result && timeVal && !this.horarioError) {
        //console.log("validated");
        this.$emit("validated");
        return true;
      }
      return false;
    },
    fetchTable() {
      this.loading = true;

      //console.log("%c%s", "color: #733d00", "date", this.res);
      this.getTable({ date: this.date, studio: this.studio.id }).then(
        (boxes) => {
          //console.log(boxes.box);
          //console.log(boxes.box2);
          this.boxes = boxes.box2;
          if (this.havePayments) {
            this.boxes = this.boxes.filter((x) => x.token_payment !== null);
          }
          this.taula = boxes.box;
          //console.log(this.taula);
          this.loading = false;
        }
      );
    },

    tattooerHours(h, box) {
      let n = this.taula.find((element) => element.id == box.id);

      if (n) {
        let hs = n.boxes_tattooer.find((x) => x.start_time == h);
        ////console.log(hs);
        if (hs) {
          let mins = [":00", ":30"];
          let hours = [];
          for (let x = 0; x < 24; x++) {
            mins.forEach((y) => {
              if (x < 10) hours.push("0" + x + y);
              else hours.push(x + y);
            });
          }
          let span = 0;
          let s = 0;

          for (let i = 0; i < hours.length; i++) {
            if (hours[i] == hs.start_time) {
              s = i;
            } else if (hours[i] == hs.end_time) {
              span = i - s;
            }
          }

          return { reserva: hs, colspan: 1, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },
    rowspan(h, box) {
      let n = this.taula.find((element) => element.id == box.id);
      if (n) {
        let hs = n.boxes_tattooer.find((x) => {
          return h >= x.start_time && h < x.end_time;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return true;
        }
      }
      return false;
    },
    rowspanSelection(h, box) {
      if (this.res.id == box.id) {
      }
      if (this.res.start <= h && this.res.end >= h && this.res.id == box.id) {
        return true;
      }
      return false;
    },
    reserva(h, box) {
      if (this.res.start == null) {
        this.res.start = h;
        this.res.id = box.id;
      } else if (
        this.res.start != null &&
        this.res.id == box.id &&
        this.res.end != null
      ) {
        this.res.start = h;
        this.res.id = box.id;
        this.res.end = null;
      } else if (this.res.start != null && this.res.id == box.id) {
        this.res.end = h;
        this.res.id = box.id;
      } else {
        this.res.start = h;
        this.res.id = box.id;
        this.res.end = null;
      }
    },
  },
};
</script>
<style lang="sass">
#time
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
    margin-top: 8px !important
#table
  min-width: 1000px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border-bottom: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border-top: 0
  td:first-child,th:first-child
    width: 50px
  tr, td:first-child, th:first-child
    border-left: 1px solid var(--v-primary-base)
  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)

    min-width: 100px
    max-width: 100px
    text-align: center
</style>